import React, { memo, useMemo } from 'react';
import {
  WidgetProps,
  I18nextProvider as I18nextProviderICU,
} from '@wix/yoshi-flow-editor';

import {
  getBaseLanguage,
  getCurrentUser,
} from '@common/store/base-props-slice';
import { getCurrentError } from '@common/store/errors-slice';
import { getTranslations } from '@common/store/translations-slice';
import {
  BiLoggerContextProvider,
  PermissionsContextProvider,
} from '@common/providers';
import { getI18nConfigICU } from '@common/utils';
import { ErrorBoundary } from '@common/components';
import { ControllerProps } from '../controller';
import { MemberComments } from './pages';

const Widget = memo<WidgetProps<ControllerProps>>((props) => {
  const translations = getTranslations(props.state);
  const language = getBaseLanguage(props.state);
  const currentUser = getCurrentUser(props.state);
  const currentError = getCurrentError(props.state);

  const i18nICU = useMemo(
    () => getI18nConfigICU(language, translations),
    [language, translations],
  );

  return (
    <I18nextProviderICU i18n={i18nICU}>
      <PermissionsContextProvider user={currentUser}>
        <BiLoggerContextProvider logBiEvent={props.actions.logBiEvent}>
          <ErrorBoundary
            error={currentError}
            handleLogError={props.actions.logError}
          >
            <MemberComments {...props} />
          </ErrorBoundary>
        </BiLoggerContextProvider>
      </PermissionsContextProvider>
    </I18nextProviderICU>
  );
});

export default Widget;
