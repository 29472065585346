import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes, st } from './ItemHeader.st.css';
import { CommentType } from '@common/store/member-slice/member-comments-slice';

interface ItemHeaderProps {
  isMobile?: boolean;
  data: CommentType;
}

const ItemHeader: FC<ItemHeaderProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={st(classes.itemContainer)}>
      <div className={st(classes.itemTitle)}>{data.post.title}</div>

      <div className={st(classes.itemSubTitle)}>
        {t('post-list-item.post-in-category', {
          category: data.category.title,
        })}
      </div>
    </div>
  );
};

export default ItemHeader;
